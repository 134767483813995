import {Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import {CookieService} from "ngx-cookie-service";
import {ConstantsHandler} from '../_common/_constant/constants.handler';


@Injectable()
export class AuthService {
    constructor(public afAuth: AngularFireAuth,
                private cookieService: CookieService,) {
    }

    // Facebook login
    doFacebookLogin() {
        return new Promise<any>((resolve, reject) => {
            const provider = new firebase.auth.FacebookAuthProvider();
            this.afAuth.signInWithPopup(provider).then(
                res => {
                    resolve(res);
                },
                err => {
                    console.log(err);
                    reject(err);
                }
            );
        });
    }

    // Github login
    doGitHubLogin() {
        return new Promise<any>((resolve, reject) => {
            const provider = new firebase.auth.GithubAuthProvider();
            this.afAuth.signInWithPopup(provider).then(
                res => {
                    resolve(res);
                },
                err => {
                    console.log(err);
                    reject(err);
                }
            );
        });
    }

    // Twitter login
    doTwitterLogin() {
        return new Promise<any>((resolve, reject) => {
            const provider = new firebase.auth.TwitterAuthProvider();
            this.afAuth.signInWithPopup(provider).then(
                res => {
                    resolve(res);
                },
                err => {
                    console.log(err);
                    reject(err);
                }
            );
        });
    }

    // Google login
    doGoogleLogin() {
        return new Promise<any>((resolve, reject) => {
            const provider = new firebase.auth.GoogleAuthProvider();
            provider.addScope('profile');
            provider.addScope('email');
            this.afAuth.signInWithPopup(provider).then(
                res => {
                    resolve(res);
                },
                err => {
                    console.log(err);
                    reject(err);
                }
            );
        });
    }

    // Register
    doRegister(value) {
        return new Promise<any>((resolve, reject) => {
            firebase
                .auth()
                .createUserWithEmailAndPassword(value.email, value.password)
                .then(
                    res => {
                        resolve(res);
                    },
                    err => reject(err)
                );
        });
    }

    // Login
    doLogin(value) {
        return new Promise<any>((resolve, reject) => {
            firebase
                .auth()
                .signInWithEmailAndPassword(value.email, value.password)
                .then(
                    res => {
                        resolve(res);
                    },
                    err => reject(err)
                );
        });
    }

    // Logout
    doLogout() {
        return new Promise<void>((resolve, reject) => {
            this.cookieService.delete(ConstantsHandler.GLOBAL_TOKEN.id);
            this.cookieService.delete(ConstantsHandler.TOKEN.cookieName);
            // await this.webNotificationMessageService.unregister();

            // if (firebase.auth().currentUser) {
            //     localStorage.removeItem('currentUser');
            //     localStorage.removeItem('remember');
            //     this.afAuth.signOut();
            //     resolve();
            // } else {
            //     localStorage.removeItem('currentUser');
            //     resolve();
            // }

            if (firebase.auth().currentUser) {
                localStorage.removeItem('remember');
                this.afAuth.signOut();
            }
            localStorage.removeItem('currentUser');
            localStorage.removeItem('CURRENT_LANG');
            resolve();
        });
    }
}
