<ifcs-modal-pdf [iid]="helpId"
                [doc]="doc"
                [fileName]="fileName"
                sizeClass="help-modal-div"
                [dlDisabled]="false"
                style="width: 820px;height: 700px;"
                title="{{'help' | translate}}"
                cancelBtnText ="{{'closeJ' | translate}}"
                okBtnText="{{'save' | translate}}"
                >
</ifcs-modal-pdf>
