import {ConstantsHandler, ServerType} from './constants.handler';

export class UrlHandler {
    static routeParam: any;

    //アプリの絶対パスを取る
    static getApiUrlRocalTest(uri: string): string {
        if(ConstantsHandler.watchServer.endsWith("/") && uri.startsWith("/")){
            return ConstantsHandler.watchServer.concat(uri.substring(1));
        }else if(ConstantsHandler.watchServer.endsWith("/") && !uri.startsWith("/")){
            return ConstantsHandler.watchServer.concat(uri);
        }else if(!ConstantsHandler.watchServer.endsWith("/") && uri.startsWith("/")){
            return ConstantsHandler.watchServer.concat(uri);
        }else if(!ConstantsHandler.watchServer.endsWith("/") && !uri.startsWith("/")){
            return ConstantsHandler.watchServer.concat('/').concat(uri);
        }
        return ConstantsHandler.watchServer.concat(uri);
    }

}
