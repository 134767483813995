import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IfcsModalModule, IfcsModalPdfModule} from "ifocus-lib";
import {HelpModalComponent} from "./help-modal.component";
import {PdfViewerModule} from "ng2-pdf-viewer";
import {TranslateModule} from '@ngx-translate/core';


@NgModule({
    declarations: [HelpModalComponent],
    imports: [
        CommonModule,
        IfcsModalModule,
        PdfViewerModule,
        IfcsModalPdfModule,
        TranslateModule
    ],
    providers: [],
    exports: [HelpModalComponent],
})
export class HelpModalModule {
}
