export class CommandConst {
    ///////////////// 送信元：端末(01系) /////////////////
    static EDGE_LOCATION = '1001';
    static EDGE_BATTERY = '1002';

    ///////////////// 送信元：サーバー(02系) /////////////////
    static SERVER_LOCATION = '2001';
    static SERVER_BATTERY = '2002';
    static SERVER_AUTH = '2004';
    static SERVER_SETTING_PERSON = '2005';
    static SERVER_SETTING_PIN_CODE = '2012';

    static TERMINAL_OPERATION = '6001';         // 端末操作
    static SERVER_SET_MACHINE_POWER = '2023';   // 電源OFF・再起動
    static SERVER_SET_KEY_LOCK = '2024';        // キーロック
    static SERVER_SETTING_VALUES = '2025';      // デバイス設定
    static TERMINAL_TRACELOG = '2026';          // トレースログ

    // 端末受信
    static COMMAND_TYPE_EDGE = '01';
    // サーバー送信
    static COMMAND_TYPE_SERVER = '02';
    // その他
    static COMMAND_TYPE_OTHER = '99';
}

export class UserSettingsTypeConst {
    // 権限
    static AUTH = '01';
    // 端末
    static MACHINE = '02';
    // アイコン
    static ICON = '03';
}

export class CompanySettingsTypeConst {
    // アイコン
    static ICON = '01';
}

export class LiveStatusConst {
    // 登録（Default）
    static LIVE_STATUS_ENTRY = '00';
    // 入室
    static LIVE_STATUS_ROOM = '01';
    // 退室（ライブ終了）
    static LIVE_STATUS_EXIT = '02';
    // ライブ
    static LIVE_STATUS_LIVE = '03';

    // NULL
    static LIVE_STATUS_NULL = '99';
}

export class DeviceAuthType {
    // ピンコード
    static PIN_CODE = '1';
    // IC
    static IC = '2';
    // 利用者
    static USER = '3';
}

