// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// WARNING - Values under "firebase" and value of "googleApiKey" needs to be replaced from your own accounts
// If left as is, it firbase and google map related functionality will not work on LIVE instance.

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.


export const environment = {
    production: false,

    // デフォルト言語
    multiLanguage: {
        supportLangs: ['en', 'ja'],
        defaultLang: 'en',
    },

    firebase: {
        apiKey: "AIzaSyAalAVEZ4ZfPran9z4ldszh5vuhz75ytm8",
        authDomain: "aaasbodycamera.firebaseapp.com",
        projectId: "aaasbodycamera",
        storageBucket: "aaasbodycamera.appspot.com",
        messagingSenderId: "1066086285237",
        appId: "1:1066086285237:web:3336f17b924c01f0fda3fc",
        vapidKey: "BODxcCaKUF7ppmoJhZSNuUqh9iXY1bk-j7ajoqpiECp-74tiv8utLIWUTQJQvRKsxTOFolzdANdJQtORyTlqfsw",
    },
    googleApiKey: 'AIzaSyAIIYOxA7qeetFz6TuR1Qewc0Rrjhzx7ZU',
    accUrl: {
        server: 'http://localhost:8080',
        bsServer: 'https://demo.trackun.jp/bs',
    },
    keycloak: {
        server: 'https://auth.aaaswatch.io/auth/realms/trackun/',
        clientId: 'trackun',
    },
    targetUser: {
        id: 'targetuserid',
        companyId: 'targetuserCompanyid',
    },

    appSetting: {
        webTitle: 'i-focus - AAAS-BodyCameraPro',
        menuTitle: 'AAASBodyCameraPro',
        // menuIcon: 'assets/custom/images/watch-app-icon-180.png',
        menuIcon: 'assets/custom/images/titleLogo.png',
        loginTitle: 'AAASBodyCameraPro',
        // loginIcon: 'assets/custom/images/logo.png',
        // loginIcon: 'assets/custom/images/titleLogo.png',
        loginIcon: 'assets/custom/images/loginicon.png',
        loginBackground: 'assets/custom/images/signin-background1.png',
        loginJump: '/home',
        verticalBackgroundColor: 'aliceblue'
    },

    ///////////////// その他 /////////////////
    other: {
        TOKEN: {
            cookieName: "ACCESS_TOKEN",
            access_token: "",
            access_token_fetch_time: 0,
            access_token_valid_time: 0,
            refresh_token: "",
            refresh_token_fetch_time: 0,
            refresh_token_valid_time: 0
        },

        tokenStatus: {
            access_token_valid: 1,
            refresh_token_valid: 2,
            refresh_token_expired: 3
        },

        GLOBAL_TOKEN: {
            id: 'PF_GLOBAL_TOKEN_BWC',
            errMsg: {
                userTokenInvalid: 'ユーザー安全認証トークンが無効になりました',
                userPasswordInvalid: 'ユーザー名とパスワードが間違います'
            },
            interval: 6 * 60 * 60 * 1000,
            timeZone: 9 * 60 * 60 * 1000
        },

        LOCAL_STORAGE: {
            PROFILE_USER: 'LOCAL_PROFILE_USER',
            LOCALE_LIST: 'LOCALE_LIST',
            CURRENT_LANG: 'CURRENT_LANG',
        },

    },

    ///////////////// API URL /////////////////
    httpEnv: {
        ///////////////////// watchServer /////////////////////
        // サーバー（リリース）
        watchServer: 'https://api.aaaswatch.io/',

        // // サーバー（ローカル）
        // watchServer: 'http://localhost:9002/',

        // // サーバー（AWS）
        // watchServer: 'http://54.150.111.136:9002/',


        ///////////////////// verifyServer /////////////////////
        // リリース
        verifyServer: 'https://api.aaaswatch.io/',

        // // ローカル
        // watchServer: 'http://localhost:9002/',


        ///////////////////// businessServer /////////////////////
        // // リリース
        // businessServer: 'https://viewapi.aaascloud.io',

        // dev
        // businessServer: 'http://172.207.81.55:9001',
        businessServer: 'https://liveview-dev.aaascloud.io/bwc',

        // // リリース（test）
        // businessServer: 'https://console.aaascloud.io/bwc',

        // // ローカル
        // businessServer: 'http://localhost:9001/',
    },

};
